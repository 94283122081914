<template>
    <b-container fluid class="p-0">
      <b-row class="heading_alignment">
        <b-col md="12" id="testseries">
          <iq-card style="padding-left:20px; padding-right:20px">
            <template v-slot:headerTitle>
                <h4 class="card-title">
                  <i class="fa fa-solid fa-graduation-cap " aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
                  {{cvCardTitle}}
                  <div class="btn_in_header">
                    <!-- Since we have only one available batch for SAT Exam, and we have to tag the quizes to their respective batches, commenting the below button so that the user cannot pay for the subsequent non-approveed quizes, in future we are going to enable the button when we have multiple batches of quiz to purchase -->

                    <!-- <b-button variant="primary" @click="goToTestPrepPdtPage" v-if="moreBatchesToBuy && userData.user_role == 'USERROLE11114'">{{cvbtnGidePrep}}</b-button> -->
                    <b-button variant="primary" @click="goToGIDEPrepDashboard" class="m-2" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11111'">{{cvbtnGIDEPrepDashboard}}</b-button>
                  </div>
                </h4>
            </template>
            <template v-slot:body>
              <b-row class="row w-100">
                <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                  <b-form-input
                    id="search"
                    v-model="filter"
                    type="search"
                    :placeholder="cvSearchText"></b-form-input
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col class="container_maxheight" md="12">
                  <div>
                    <b-row class="p-2">
                      <div v-for="(mcObj,index) in moduleCountObj" :key="index" class="mt-3 col-12 col-sm-6 col-md-4 col-lg-3">
                        <iq-card className="iq-card-block iq-card-stretch iq-card-height side_border">
                          <template v-slot:body>
                            <div class="iq-badges text-left" style="cursor: pointer;">
                              <h5 class="title pl-2 twoLineOnly pr-3" :title="mcObj.subject">
                                  {{ mcObj.subject }}
                                  <span class="pull-right" title="Available Modules">{{ mcObj.total_modules }}</span><br>
                              </h5>
                              <div class="float-right mt-2">
                                <b-button  variant="iq-bg-primary mr-1 mb-0" size="sm" @click="goToModuleView(mcObj.interest_id)"><i class="fa fa-eye m-0"></i></b-button>
                              </div>
                            </div>
                          </template>
                        </iq-card>
                      </div>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { TPrepSubjectModules } from "../../../FackApi/api/TPrepSubjectModule"
import { User } from "../../../FackApi/api/user"
import { socialvue } from "../../../config/pluginInit"
import { TprepSessions } from "../../../FackApi/api/TprepSession"
import TestSeriesConfigJson from "../../../FackApi/json/TestSeries.json"

export default {
  name: "Home",
  data () {
    return {
      TestSeriesConfigJson: TestSeriesConfigJson,
      apiName: "user_interest_list",
      showInterestModal: false,
      moduleCountObj: [],
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Subject/Test", key: "subject", class: "text-left align-text-top" },
        { label: "Available Modules", key: "total_modules", class: "text-left align-text-top" }
      ],
      cvSearchText: "Type to search",
      cvCardTitle: "TestSeries Student Dashboard",
      filter: null,
      filterOn: [],
      userSubject: null,
      totalRows: null,
      cvbtnGidePrep: "Add (+)",
      cvbtnGIDEPrepDashboard: "TestSeries Dashboard",
      moreBatchesToBuy: false,
      userSubjectInterest: null,
      gide_user_id: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async mounted () {
    socialvue.index()
    this.gide_user_id = this.$route.query.gide_user_id ? this.$route.query.gide_user_id : this.userData.user_id

    await this.getUserSubjectInterest()
    await this.checkPayment()
    this.getModules()
  },
  methods: {
    /**
     * goToTestPrepPdtPage
     */
    goToTestPrepPdtPage () {
      this.$router.push(`/test_prep_target/${TestSeriesConfigJson.TESTSERIES_SAT_PRODUCT_PAGE_APP_ID}`)
    },
    /**
     * emitCloseInterestModal
     */
    emitCloseInterestModal (skippingThisArgument, userInterests) {
      const userSubjects = Object.keys(userInterests).filter((intrst) => {
        return userInterests[intrst].interest_category_id == "INTERESTCAT11117"
      })

      this.userSubject = userSubjects
      this.getModules(userSubjects)
      this.$store.dispatch("User/refreshUserInterests")
    },
    /**
     * getUserSubjectInterest
     */
    async getUserSubjectInterest () {
      try {
        const userInterestListResp = await User.userInterestList(this, this.gide_user_id)
        if (!userInterestListResp) {
          this.$router.push("/home")
          return
        }
        this.userSubjectInterest = userInterestListResp.resp_data.interest_list.INTERESTCAT11117.map(e => e.interest_id)
      }
      catch (err) {
        console.error("Exception in getUserSubjectInterest and err: ", err)
      }
    },
    /**
     * getModules
     */
    async getModules (userSubjects = null) {
      try {
        const tprepModuleCountObj = {}

        if (this.$route.query.gide_user_id && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
          // Admin/Counsellor trying to view the subjects of another user
          tprepModuleCountObj.gide_user_id = this.$route.query.gide_user_id
        }

        if (!userSubjects) {
          tprepModuleCountObj.filter = this.userSubjectInterest
        }
        else {
          tprepModuleCountObj.filter = userSubjects
        }

        const moduleCountResp = await TPrepSubjectModules.tprepSubjectModuleCount(this, tprepModuleCountObj)
        if (moduleCountResp.resp_status) {
          this.moduleCountObj = moduleCountResp.resp_data.data
          this.totalRows = moduleCountResp.resp_data.count
        }
        else {
          this.moduleCountObj = []
          this.toastMsg = moduleCountResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at getModules() and Exception:", err.message)
      }
    },
    /**
     * goToModuleView
     */
    goToModuleView (subjectId) {
      if (this.$route.query.gide_user_id) {
        this.$router.push(`/subject_modules/${subjectId}?gide_user_id=${this.$route.query.gide_user_id}`)
      }
      else {
        this.$router.push(`/subject_modules/${subjectId}`)
      }
    },
    /**
     * checkPayment
     */
    async checkPayment () {
      try {
        // check if the user has a session, if it has then redirect the user to teest_prep
        let hasUserPaid = null
        if (this.$route.query.gide_user_id && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
          hasUserPaid = await TprepSessions.tprep_sessionCheckPayment(this, { gide_user_id: this.$route.query.gide_user_id, user_subject_interest: this.userSubjectInterest })
        }
        else {
          hasUserPaid = await TprepSessions.tprep_sessionCheckPayment(this, { gide_user_id: this.gide_user_id, user_subject_interest: this.userSubjectInterest })
        }
        if (!hasUserPaid.resp_status) {
          // If the user has landed on this page and the check payment fails, redirect to /home cause the user may have landed from the dashboard page and that page redirects to this one, thereby triggering an infinite loop of redirection
          this.$router.push("/home")
        }
        else if (hasUserPaid.modules_not_purchased.length > 0) {
          this.moreBatchesToBuy = true
        }
      }
      catch (error) {
        console.error("Exception in checkPayment and err: ", error)
      }
    },
    /**
     * goToGIDEPrepDashboard
     */
    goToGIDEPrepDashboard () {
      this.$router.push("/testseries_dashboard")
    }
  }
}
</script>
<style scoped>
.zero-screen {
  height: calc(100vh - 130.19px);
}
</style>
